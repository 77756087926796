<template>
    <div class="loading-overlay">
        <div class="loading-content">
            <i class="fas fa-sync fa-spin fa-2x"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'loading'
    };
</script>

<style scoped lang="scss">

    .loading-overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .7);
        z-index: 999;
        max-height: 100vh;
        max-width: 100vw;
    }

    .loading-content {
        max-width: 90vw;
        padding: 1rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

</style>