<template>
    <div class="notice" :class="type" v-if="isOpen" @click.stop.prevent="close">
        <span class="message">{{ message }}</span>
    </div>
</template>

<script>
    export default {
        name: 'notice',
        props: {
            message: { type: String, default: null },
            type: { type: String, default: '' },
            ttl: { type: [Boolean, Number], default: false },
        },
        data() {
            return {
                isOpen: true,
            }
        },
        mounted() {
            if (this.ttl) {
                setTimeout(this.close, this.ttl * 1000);
            }
        },
        methods: {
            close() {
                this.isOpen = false;
                this.$emit('closed');
            },
        },
    };
</script>

<style scoped lang="scss">
    .notice {
        position: relative;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
        z-index: 999;
        padding: 1rem 1.5rem 1rem 1rem;
        border-radius: .25rem;
        background: rgba(239, 175, 14, .95);
        color: #fff;

        &.error {
            background: rgba(239, 14, 14, .95);
        }
    }
</style>
