<template>
    <div class="container page">

        <v-post :title="post.title"
                :outline="post.outline"
                :comments="post.num_comments"
                :score="post.score"
                :thumbnail="post.thumbnail"
                :url="post.url"
                :comments-url="post.id"
                :meta="post"
                :loading="!loaded"
                ref="post"
        >
        </v-post>

        <hr>

        <div class="comments">
            <template v-if="!loading">
                <v-comment v-for="comment in comments"
                           :body="comment.body"
                           :author="comment.author"
                           :created="comment.created"
                           :meta="comment"
                ></v-comment>
            </template>
            <template v-else>
                <div class="comment-placeholder" v-for="n in 15" :style="getCommentStyle(n)"></div>
            </template>
        </div>
    </div>

</template>

<script>

    import { authorizedGet } from '../lib/request';
    import { fetch, store } from '../lib/cache';
    import { EventBus } from '../lib/event-bus';
    import pluck from '../lib/pluck';
    import { purgeScrollPosition } from '../lib/scrollPosition';

    import vComment from '../components/comment';
    import vPost from '../components/post';

    const CACHE_TTL = false;

    const commentKeys = ['body', 'author', 'score', 'created', 'replies', 'body_html', 'link_id'];

    export default {
        name: 'comments',
        components: { vPost, vComment },
        data () {
            return {
                loading: false,
                loaded: false,
                id: null,
                subreddit: null,
                post: {},
                comments: [],
            };
        },
        mounted () {
            this.id = this.$route.params.id;
            this.subreddit = this.$route.params.subreddit;
            this.fetchData();

            EventBus.$on('refresh', () => {
                purgeScrollPosition(window.location.pathname);
                this.fetchData(false);
            });

        },
        watch: {
            loading (value) {
                this.$emit('loading', value);
            },
            post (value) {
                if (value.is_self) {
                    this.$refs.post.previewOpen = true;
                }
            }
        },
        methods: {

            async fetchData (useCached = true) {

                if (!this.loading) {
                    this.loading = true;

                    const cacheKey = `comments.${this.id}`;
                    const cachedResult = await fetch(cacheKey);
                    if (useCached && cachedResult) {
                        this.post = cachedResult.post;
                        this.comments = cachedResult.comments;
                        this.loading = false;
                        this.loaded = true;
                    } else {

                        authorizedGet(`https://oauth.reddit.com/r/${this.subreddit}/comments/${this.id}`)
                            .then(async (res) => {
                                this.loaded = true;
                                this.post = res.data[0].data.children[0].data;
                                this.comments = res.data[1].data.children.map(comment => pluck(comment.data, commentKeys));
                                await store(cacheKey, { post: this.post, comments: this.comments }, CACHE_TTL);
                            })
                            .catch(err => {
                                console.error(err);
                                EventBus.$emit('error', err);
                            })
                            .finally(() => {
                                this.loading = false;
                            });

                    }

                }

            },

            getCommentStyle (n) {

                const rand = (min, max) => {
                    return min + Math.random() * (max - min);
                };

                return {
                    width: rand(15, 100) + '%',
                };

            }

        },
        deactivated () {
            this.$destroy();
        }
    };
</script>

<style scoped lang="scss">
.container {
  margin-top: .5rem;
}

.comment-placeholder {
  background: #eee;
  height: 1rem;
  margin-bottom: 1rem;
}
</style>
