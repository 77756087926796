<template>
    <div id="app">
        <v-modal v-if="modalOpen">
            <component
                :is="modalContent"
                v-bind="modalProps"
            />
        </v-modal>
        <div class="notice-container">
            <v-notice
                v-for="(notice, idx) in notices"
                :key="notice.key"
                :type="notice.type || ''"
                :message="notice.message"
                :ttl="notice.ttl || false"
                @closed="removeNotice(idx)"
            >
            </v-notice>
        </div>

        <v-loading v-if="loading"></v-loading>

        <header>
            <div class="container">
                <a
                    href="#"
                    class="home-link"
                    @click.prevent="handleHomeClick"
                >Readdit</a>
                <div
                    class="spacer"
                    @submit.prevent="handleOmniSubmit"
                >
                    <form class="omni-bar">
                        <input
                            ref="omniBar"
                            v-model="omniValue"
                            type="text"
                        >
                    </form>
                </div>
                <a
                    href="#"
                    @click.prevent="refresh"
                ><i class="fas fa-sync" /></a>
            </div>
        </header>

        <router-view @loading="handleLoading" />
    </div>
</template>

<script>

    import VueRouter from 'vue-router';
    import Vue from 'vue';
    import md5 from 'md5';

    import routes from './lib/routes';
    import { EventBus } from './lib/event-bus';
    import {
        debouncedScrollTo,
        fetchScrollPosition,
        purgeScrollPosition,
        storeScrollPosition,
    } from './lib/scrollPosition';
    import throttle from './lib/throttle';

    import vPost from './components/post.vue';
    import vModal from './components/modal.vue';
    import vNotice from './components/notice.vue';
    import vLoading from './components/loading.vue';

    const router = new VueRouter({
        mode: 'history',
        routes,
        scrollBehavior(to, from, savedPosition) {
            // console.log(to, from, savedPosition);
            if (to.name === 'comments') return { x: 0, y: 0 };
            if (to.params.subreddit !== from.params.subreddit) return { x: 0, y: 0 };
            return savedPosition;
        },
    });

    router.beforeResolve((to, from, next) => {
        if (from) {
            storeScrollPosition(from.path);
        }
        next();
    });

    router.afterEach((to, from) => {
        Vue.nextTick(() => {
            let title = 'Readdit';
            if (to.params.subreddit) title += ` | ${to.params.subreddit}`;
            if (to.name === 'comments') title += ' | comments';
            if (to.params.sort) title += ` | ${to.params.sort}`;
            document.title = title;
        });
    });

    export default {
        name: 'App',
        router,
        components: {
            vPost, vModal, vNotice, vLoading,
        },
        data() {
            return {
                modalOpen: false,
                notices: [],
                loading: false,
                omniValue: null,
                darkMode: false,
            };
        },
        computed: {},
        watch: {
            darkMode(v) {
                console.log('x', v);
                const b = document.body;
                if (v) b.classList.add('dark');
                else b.classList.remove('dark');
            },
        },
        created() {
            throttle('scroll', 'optimisedScroll');
            window.addEventListener('pageshow', () => {
                const pos = fetchScrollPosition(window.location.pathname);
                if (pos) {
                    debouncedScrollTo(pos);
                }
            }, false);
            window.addEventListener('optimisedScroll', (e) => {
                storeScrollPosition(window.location.pathname);
            });
            EventBus.$on('error', (err) => {
                this.addNotice({ type: 'error', ...err });
            });
            EventBus.$on('notice', (message) => {
                this.addNotice({ type: 'notice', message, ttl: 30 });
            });
            if (typeof window.matchMedia === 'function') {
                const m = window.matchMedia('(prefers-color-scheme: dark)');
                this.darkMode = m.matches;
                window.matchMedia('(prefers-color-scheme: dark)')
                  .addEventListener('change', event => this.darkMode = event.matches);
            }
        },
        methods: {
            handleLoading(isLoading) {
                this.loading = isLoading;
                if (!isLoading) {
                    this.$nextTick(() => {
                        const pos = fetchScrollPosition(window.location.pathname);
                        if (pos) {
                            debouncedScrollTo(pos);
                        }
                    });
                }
            },
            refresh() {
                EventBus.$emit('refresh');
            },
            handleHomeClick() {
                router.push({ name: 'posts', params: { sort: 'hot' } });
            },
            handleOmniSubmit() {
                router.push({ name: 'subreddit', params: { subreddit: this.omniValue } });
                this.omniValue = null;
                this.$refs.omniBar.blur();
            },
            addNotice(notice) {
                const key = md5(JSON.stringify(notice.message));
                const idx = this.notices.findIndex((n) => n.key === key);
                if (idx !== -1) {
                    this.notices.splice(idx, 1);
                }
                this.notices.push({ key, ...notice })
            },
            removeNotice(idx) {
                this.notices.splice(idx, 1);
            },
        },
    };
</script>

<style scoped lang="scss">

    #app {
        position: relative;
        min-height: 100vh;
    }

    .notice-container {
        position: fixed;
        left: 50%;
        top: 10%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-flow: row wrap;
        z-index: 99;
        width: 90%;
        align-items: center;
        justify-content: center;

        .notice {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
    }

    header {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.75);
        margin: 0;
        padding: .5rem;
        color: #fff;
        width: 100%;
        z-index: 1;

        .container {
            display: flex;
            align-items: center;

            .spacer {
                flex-grow: 1;
            }

            .home-link {
                text-transform: uppercase;
                font-size: .9rem;
                font-weight: bold;
            }

            .omni-bar {
                margin: 0;
                padding: 0 1rem;

                input[type=text] {
                    border: 1px solid #222;
                    margin: 0;
                    padding: 2px;
                    background: transparent;
                    width: 100%;
                    outline: none;
                    border-radius: 4px;
                    line-height: 1rem;
                    color: #eee;

                    &:focus {
                        background: #eee;
                        color: #999;
                    }
                }
            }
        }

    }

</style>
<style lang="scss">

    html {
        font-family: sans-serif;
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: transparent;
    }

    html, body {
        overflow-x: hidden;
    }

    *, ::after, ::before {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
        background-color: #fff;
        position: relative;

        &.dark {
            background-color: #333;
            color: #eee;

            .md {
                color: #eee;

                a {
                    color: #557c88;
                }
            }

            .type-options {
                background: #333 !important;
            }

        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .container {
        padding-right: .5rem;
        padding-left: .5rem;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        max-width: 1200px;

        &.page {
            padding-top: 47px;
        }
    }

    .self-text, .comment {

        .md {
            p {
                margin: 0 0 .5rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

    }
</style>
