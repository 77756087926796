<template>
    <div class="user">
        <i class="far fa-user" v-if="!hideIcon"></i>
        <span class="value" @click="showDetails">{{ username }}</span>
        <div class="user-details-overlay" v-if="isOpen" @click.prevent.stop="isOpen = false" />
        <div class="user-details-container" v-if="isOpen">
            <template v-if="loading">Loading...</template>
            <template v-else>
                <div class="col">
                    <img :src="userImage" alt="" class="user-img" :class="{loading: loadingImage}" />
                </div>
                <div class="col">
                    <div class="user-detail title">{{ username }}</div>
                    <div class="user-detail">{{ age }}</div>
                    <div class="user-detail">
                        <i class="fas fa-comment"></i> {{ user.comment_karma }}
                        <i class="fas fa-link"></i> {{ user.link_karma }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import { authorizedGet } from '../lib/request';
    import { fetch, store } from '../lib/cache';
    import distanceToNow from 'date-fns/formatDistanceToNow';
    import defaultImage from '../images/avatar_default.png';

    const CACHE_TTL = 1800; // 30 minutes

    export default {
        name: 'User',
        props: {
            username: { type: String, default: null },
            hideIcon: Boolean
        },
        data () {
            return {
                isOpen: false,
                loading: false,
                user: null,
                userImage: defaultImage,
                loadingImage: false,
            };
        },
        computed: {
            age () {
                return distanceToNow(this.user.created_utc * 1000);
            }
        },
        methods: {
            showDetails () {
                this.isOpen = true;
                if (!this.loading) {
                    this.loading = true;
                    this.fetchUserDetails()
                      .then((user) => {
                          // console.log(user);
                          this.user = user;
                          this.fetchUserImage(user.icon_img);
                      })
                      .finally(() => {
                          this.loading = false;
                      });
                }
            },
            async fetchUserDetails () {
                const cacheKey = `user.${this.username}`;
                const userDetails = await fetch(cacheKey);
                if (userDetails) {
                    return Promise.resolve(userDetails);
                }
                return new Promise((resolve, reject) => {
                    const url = `https://oauth.reddit.com/user/${this.username}/about`;
                    authorizedGet(url)
                      .then(async (res) => {
                          await store(cacheKey, this.userDetails, CACHE_TTL);
                          resolve(Object.freeze(res.data.data));
                      })
                      .catch(reject);
                });
            },
            fetchUserImage (url) {
                if (!this.loadingImage) {
                    this.loadingImage = true;
                    const img = document.createElement('img');
                    img.addEventListener('load',()=>{
                        this.userImage = url;
                        this.loadingImage = false;
                    })
                    img.addEventListener('error',()=>{
                        this.loadingImage = false;
                    });
                    img.src = url;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .user {
        display: inline-block;
    }

    .user-details-overlay {
        left: 0;
        top: 0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .5);
        z-index: 101;

        .user-details-container {
            position: absolute;
            max-width: 90vw;
            max-height: 90vh;
            width: 320px;
            background: rgba(255, 255, 255, .95);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            overflow-y: scroll;
            padding: 1rem;
            border-radius: 4px;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.75);
            display: flex;

            .col {
                flex-shrink: 1;
                margin-right: .5rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            .user-img {
                width: 100px;
                height: 100px;
                &.loading {
                    opacity: .5;
                }
            }

            .user-detail {
                &.title {
                    font-weight: bold;
                }
            }

        }
    }
</style>
