<template>
    <div class="comment">
        <div class="header">
            <div>
                <a href="#" @click.prevent="open = !open" class="toggler">
                    <span v-if="open">[-]</span>
                    <span v-else>[+]</span>
                </a>
            </div>
            <v-user :username="author" hide-icon class="lighter"></v-user>
            <div class="score lighter">
                <i class="far fa-dot-circle"></i>
                {{ score }}
            </div>
            <div class="posted lighter">
                <i class="fas fa-clock"></i>
                {{ posted }}
            </div>
        </div>
        <template v-if="open">
            <div class="body" v-html="bodyHtml"></div>
            <div class="replies">
                <comment v-for="reply in replies"
                         :body="reply.body"
                         :author="reply.author"
                         :score="reply.score"
                         :created="reply.created"
                         :meta="reply"
                ></comment>
                <v-more v-for="link in moreLinks"
                        :link-id="link.linkId"
                        :children="link.children"
                        @loaded="addMore"
                ></v-more>
            </div>
        </template>
    </div>
</template>

<script>

    import distanceToNow from 'date-fns/formatDistanceToNow';
    import shortNumber from 'short-number';

    import vMore from './more';
    import vUser from './user'

    export default {
        name: 'comment',
        props: {
            author: String,
            body: String,
            created: Number,
            meta: Object,
        },
        components: { vMore, vUser },
        data () {
            return {
                open: true,
                replies: [],
                moreLinks: [],
            };
        },
        created () {
            if (this.meta.replies) {
                this.handleThings(this.meta.replies.data.children);
            }
        },
        computed: {
            posted () {
                if (!this.created) return '';
                return distanceToNow(this.created * 1000).replace(/about/, '');
            },
            score () {
                if (!this.meta | typeof this.meta.score === 'undefined') return '';
                return shortNumber(this.meta.score);
            },
            bodyHtml () {
                const txt = document.createElement('textarea');
                txt.innerHTML = this.meta.body_html;
                return txt.value;
            }
        },
        methods: {
            handleThings (things) {
                things.forEach(child => {
                    if (child.kind === 'more') {
                        const link = {
                            children: child.data.children,
                            linkId: this.meta.link_id,
                        };
                        if (link.children.length > 0) {
                            this.moreLinks.push(link);
                        }
                    } else {
                        this.replies.push(child.data);
                    }
                });
            },
            addMore (more) {
                this.handleThings(more);
            }
        }
    };
</script>

<style scoped lang="scss">

    .replies {
        margin-left: .5rem;
    }

    .header {
        font-size: .8rem;
        margin-top: .25rem;
        margin-bottom: .25rem;
        display: flex;

        & > div {
            margin-right: .5rem;
            white-space: nowrap;

            &.lighter {
                color: #999;
            }

            .fas,
            .far {
                font-size: .7rem;
            }

            &.author {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

        .toggler {
            color: inherit;
            text-decoration: none;
            white-space: nowrap;
        }
    }

</style>
<style lang="scss">

    .md {
        color: #222;
        overflow-wrap: break-word;
        word-wrap: break-word;

        blockquote {
            padding-left: .5rem;
            margin-left: .5rem;
            border-left: 2px solid #999;
        }

        p {
            font-size: .9rem;
            margin-top: 0;
            line-height: 1.4;
        }

        a {
            color: #009;
        }

    }
</style>
