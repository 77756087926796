<template>

    <div v-if="!loaded">
        <a href="#" @click.prevent="fetchComments" class="more-link">
            <i class="fas fa-comment"></i>
            <template v-if="loading">
                Loading...
            </template>
            <template v-else>
                Load more comments
            </template>
        </a>
    </div>
</template>

<script>

    import { authorizedPost } from '../lib/request';

    export default {
        name: 'more',
        props: {
            children: Array,
            subreddit: String,
            linkId: String,
        },
        data () {
            return {
                loaded: false,
                loading: false,
                comments: [],
            };
        },
        methods: {
            fetchComments () {

                if (!this.loading) {

                    this.loading = true;

                    authorizedPost('https://oauth.reddit.com/api/morechildren', {
                        api_type: 'json',
                        link_id: this.linkId,
                        children: this.children,
                    })
                      .then(res => {
                          if (res.data.json) {
                              if (res.data.json.data) {
                                  this.$emit('loaded', res.data.json.data.things);
                              }
                          }
                      })
                      .catch(err => {
                          console.error(err);
                      })
                      .finally(() => {
                          this.loading = false;
                          this.loaded = true;
                      });

                }

            }
        }
    };
</script>

<style scoped>

    .more-link {
        color: inherit;
        font-size: .8rem;
        text-decoration: none;

        /*display: none;*/
    }
</style>