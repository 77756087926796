import { addSeconds, isBefore } from 'date-fns';
import Dexie from 'dexie';

const db = new Dexie('readdit');
db.version(1).stores({ cache: 'key,value,expires' });

export const store = async (key, value, ttl = false) => {
    let expires = false;
    if (ttl) {
        expires = addSeconds(new Date(), ttl).getTime();
    }
    await db.cache.put({ key, value, expires });
};

export const update = async (key, value, ttl = false) => {
    const old = await fetch(key);
    if (old) {
        await db.cache.update(key, { value });
    } else {
        await store(key, value, ttl);
    }
};

export const purge = async (key) => {
    await db.cache.delete(key);
};

export const fetch = async (key) => {
    let item = await db.cache.get(key);
    if (item) {
        if (item.expires) {
            const expiryDate = new Date(item.expires);
            if (isBefore(new Date(), expiryDate)) {
                // console.log(`cache hit: ${key}`);
                return item.value;
            } else {
                // console.log(`cached item, ${key}, has expired`);
                await purge(key);
            }
        } else {
            // has no expiry
            // console.log(`cache hit: ${key}`);
            return item.value;
        }
    }
    // console.log(`cache miss: ${key}`);
    return false;
};
