<template>
    <div class="row preview" v-if="component">
        <div class="col">
            <component :is="component" v-bind="componentProps"></component>
        </div>
    </div>
</template>

<script>

    import decodeHtml from '../lib/decode-html';

    import vImage from './preview-image';
    import vVideo from './preview-video';
    import vText from './preview-text';
    import vEmbed from './preview-embed';

    export default {
        name: 'preview',
        components: { vImage, vVideo, vText, vEmbed },
        props: {
            post: Object
        },
        data () {
            return {
                componentProps: {},
                component: false,
            };
        },
        mounted () {
            this.getComponent();
        },
        deactivated () {
            this.$destroy();
        },
        methods: {
            decodeHtml (html) {
                return decodeHtml(html);
            },
            getComponent () {

                if (this.post.is_self) {
                    this.component = vText;
                    this.componentProps = {
                        html: this.decodeHtml(this.post.selftext_html)
                    };
                } else {

                    if (this.post.media) {

                        const media = this.post.media;

                        if (media.hasOwnProperty('reddit_video')) {

                            this.component = vVideo;
                            const redditVideo = media.reddit_video;
                            const sources = [];

                            if (redditVideo.hasOwnProperty('hls_url')) {
                                sources.push({ src: redditVideo.hls_url, type: 'application/x-mpegURL' });
                            }

                            if (redditVideo.hasOwnProperty('fallback_url')) {
                                sources.push({ src: redditVideo.fallback_url, type: 'video/mp4' });
                            }

                            this.componentProps = {
                                sources
                            };

                            return;

                        }

                        if (media.hasOwnProperty('oembed')) {

                            this.component = vEmbed;
                            let html = this.decodeHtml(media.oembed.html);

                            // console.log(html);
                            // remove height
                            html = html.replace(/height=["|'].*?["|']/,'');

                            // update width
                            html = html.replace(/width=["|'].*?["|']/,`width="100%"`);

                            this.componentProps = {
                                html
                            };

                            return;

                        }

                    } else {

                        const preview = this.post.preview;

                        if (preview) {

                            if (preview.reddit_video_preview) {

                                this.component = vVideo;
                                const sources = [];

                                if (preview.reddit_video_preview.hasOwnProperty('fallback_url')) {
                                    sources.push({ src: this.decodeHtml(preview.reddit_video_preview.fallback_url), type: 'video/mp4' });
                                }

                                this.componentProps = {
                                    sources
                                };

                                return;

                            }

                            if (preview.images && preview.images.length > 0) {
                                this.componentProps = {
                                    images: preview.images
                                };

                                this.component = vImage;

                                if (preview.images[0].variants) {

                                    const variants = preview.images[0].variants;

                                    if (variants.hasOwnProperty('mp4')) {
                                        this.component = vVideo;
                                        this.componentProps = {
                                            sources: [
                                                { src: this.decodeHtml(variants.mp4.source.url), type: 'video/mp4' }
                                            ]
                                        };
                                        return;
                                    }

                                    if (variants.hasOwnProperty('gif')) {
                                        this.componentProps = {
                                            src: this.decodeHtml(variants.gif.source.url)
                                        };
                                        return;
                                    }


                                }

                            }

                        }

                    }

                }

            }
        }
    };
</script>

<style lang="scss" scoped>
    .preview {
        position: relative;
        margin-top: .5rem;
    }


</style>
