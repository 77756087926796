<template>
    <nav class="navigation">
        <ul>
            <li class="item">
                <router-link :to="{ name: routeName, params: { subreddit: subreddit, sort: 'hot'} }">Hot</router-link>
            </li>
            <li class="item">
                <router-link :to="{ name: routeName, params: { subreddit: subreddit, sort: 'new'} }">New</router-link>
            </li>
            <li class="item">
                <router-link :to="{name: routeName, params: { subreddit: subreddit, sort: 'top'} }">Top ({{sortType}})</router-link>
                <template v-if="sort === 'top'">
                    <button @click.prevent="typeOptionsOpen = !typeOptionsOpen" class="toggle-type">
                        <i class="fas fa-filter"></i>
                    </button>
                    <ul v-if="typeOptionsOpen" class="type-options">
                        <li v-for="type in sortTypes" class="item">
                            <router-link :to="{name: routeName, params: { sort: 'top', sortType: type}}">{{ type }}</router-link>
                        </li>
                    </ul>
                </template>
            </li>
        </ul>
    </nav>
</template>

<script>

    export default {
        name: 'sort',
        props: {
            subreddit: String | Boolean
        },
        data () {
            return {
                sortTypes: ['hour', 'day', 'week', 'month', 'year', 'all'],
                sort: this.$route.params.sort || 'hot',
                sortType: this.$route.params.sortType || 'all',
                typeOptionsOpen: false,
            };
        },
        watch: {
            $route (to) {
                this.sort = to.params.sort || 'hot';
                this.sortType = to.params.sortType || 'all';
                this.typeOptionsOpen = false;
            }
        },
        computed: {
            routeName () {
                return !!this.subreddit ? 'subreddit' : 'posts';
            }
        }

    };
</script>

<style scoped lang="scss">
    .navigation {

        border-bottom: 3px solid #eee;
        margin-bottom: .5rem;
        padding-bottom: .5rem;
        padding-top: .5rem;

        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            & > .item {
                position: relative;
                margin-right: .5rem;
                font-size: .8rem;
                text-transform: uppercase;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            .router-link-active {
                font-weight: bold;
            }
        }

        .toggle-type {
            border: none;
            margin: 0;
            padding: 0;
        }

        .type-options {
            position: absolute;
            background: #fff;
            border: 2px solid #eee;
            border-radius: 4px;
            top: 2.4rem;
            left: 50%;
            z-index: 2;
            flex-direction: column;
            padding: 1rem;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-left: 1.2rem solid transparent;
                border-right: 1.2rem solid transparent;
                border-bottom: 1.2rem solid #eee;
                position: absolute;
                top: -1.2rem;
                margin-left: -.2rem;
            }
            &:after{
                content: '';
                width: 0;
                height: 0;
                border-left: 1rem solid transparent;
                border-right: 1rem solid transparent;
                border-bottom: 1rem solid #fff;
                position: absolute;
                top: -1rem;
            }
        }

    }
</style>