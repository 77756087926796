<template>
    <div class="container page">
        <div class="row">
            <div class="col">
                <p><i class="fas fa-error"></i>Error</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'error'
    };
</script>

<style scoped>

</style>
