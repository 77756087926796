import md5 from 'md5';
import debounce from 'lodash.debounce';

const keyPrefix = 'scrollPos';

const positionKey = (path) => {
    // console.log(`positionKey: ${path}`);
    return `${keyPrefix}-${md5(path).substr(0, 5)}`;
};

const storeScrollPosition = (path) => {
    const key = positionKey(path);
    const pos = window.scrollY;
    if (pos > 0) {
        // console.log(`set: ${path}: ${pos}`);
        sessionStorage.setItem(key, String(pos));
    }
};

const fetchScrollPosition = (path) => {
    if (path) {
        const key = positionKey(path);
        const scrollPos = sessionStorage.getItem(key);
        // console.log(`get: ${path}: ${scrollPos}`);
        sessionStorage.removeItem(key);
        return scrollPos;
    }
    return false;
};

const debouncedScrollTo = debounce((position) => {
    // console.log(`scrollTo: ${position}`);
    window.scrollTo(0, position);
}, 100, { trailing: true });

const purgeScrollPosition = (path) => {
    if (path) {
        const key = positionKey(path);
        // console.log(`purge scroll: ${path}`);
        sessionStorage.removeItem(key);
    } else {
        // console.log(`purging all scroll positions: (${sessionStorage.length})`);
        const rgx = new RegExp(`^${keyPrefix}-(.*)$`);
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (key && rgx.test(key)) {
                sessionStorage.removeItem(key);
            }
        }
    }
};

export {
    storeScrollPosition,
    fetchScrollPosition,
    debouncedScrollTo,
    purgeScrollPosition,
};
