<template>
    <div class="modal-overlay">
        <div class="modal-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal'
    };
</script>

<style scoped lang="scss">

    .modal-overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .7);
        z-index: 999;
    }

    .modal-content {
        max-width: 90vw;
        background: rgba(255, 255, 255, .95);
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.75);
        padding: 1rem;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

</style>
