import Posts from '../pages/subreddit';
import Comments from '../pages/comments';
import Error from '../pages/error';

const forceTop = (to, from, next) => {
    if (!to.params.sort) {
        return next({ ...to, params: { ...to.params, sort: 'hot' } });
    }
    next();
};

export default [
    { path: '/r/:subreddit/comments/:id', name: 'comments', component: Comments },
    {
        path: '/r/:subreddit?/:sort?/:sortType?',
        name: 'subreddit',
        component: Posts,
        beforeEnter: forceTop
    },
    {
        path: '/:sort?/:sortType?',
        name: 'posts',
        component: Posts,
        beforeEnter: forceTop
    },
    { path: '*', component: Error }
]