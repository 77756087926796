<template>
    <video controls autoplay playsinline class="preview-video">
        <source v-for="source in sources" :src="source.src" :type="source.type">
        Sorry your browser doesn't support embedded videos
    </video>
</template>

<script>
    export default {
        name: 'previewVideo',
        props: {
            sources: Array
        }
    };
</script>

<style scoped>
    .preview-video {
        max-width: 100%;
        max-height: 90vh;
        width: 100%;
    }
</style>
