import axios from 'axios';
import { v1 as uuid } from 'uuid';
import querystring from 'querystring';
import { isAfter, addSeconds } from 'date-fns';

import config from '../config';

const getState = () => {

    let state = localStorage.getItem('state');
    if (!state) {
        state = uuid();
        localStorage.setItem('state', state);
    }

    return state;

};

const scopes = [
    'identity',
    'mysubreddits',
    'read',
    'report',
    'subscribe',
    'vote'
];

const getLoginUrl = () => {

    const queryParams = {
        client_id: config.clientId,
        response_type: 'code',
        state: getState(),
        redirect_uri: config.redirectUri,
        scope: scopes.join(','),
        duration: 'permanent',
    };

    return `https://www.reddit.com/api/v1/authorize.compact?${querystring.stringify(queryParams)}`;

};

const getToken = () => {

    let token = localStorage.getItem('access_token');
    let tokenExpiresAt = new Date(Number(localStorage.getItem('token_expires')));

    if (token && isAfter(tokenExpiresAt, new Date())) {
        // just return the token if it exists and hasn't expired
        return Promise.resolve(token);
    }

    const doRequest = (formData) => {

        return new Promise((resolve, reject) => {

            axios({
                method: 'post',
                mode: 'no-cors',
                url: 'https://www.reddit.com/api/v1/access_token',
                data: formData,
                auth: {
                    username: config.clientId,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(res => {
                    if (res.data.error) {
                        reject(res.data.error);
                    } else {
                        let token = res.data.access_token;
                        localStorage.setItem('access_token', token);
                        if (res.data.expires_in) {
                            tokenExpiresAt = addSeconds(new Date(), res.data.expires_in);
                            localStorage.setItem('token_expires', String(tokenExpiresAt.getTime()));
                        }
                        if (res.data.refresh_token) {
                            localStorage.setItem('refresh_token', res.data.refresh_token);
                        }
                        resolve(token);
                    }
                })
                .catch(err => reject(err))
            ;

        });

    };

    // if we have refresh token we'll try to refresh the existing token
    let refresh_token = localStorage.getItem('refresh_token');
    if (token && refresh_token) {

        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', localStorage.getItem('refresh_token'));
        return doRequest(formData, true);

    } else {

        const queryParams = querystring.parse(window.location.search.substr(1));

        if (queryParams.code) {

            if (queryParams.state === getState()) {

                const formData = new FormData();
                formData.append('grant_type', 'authorization_code');
                formData.append('code', queryParams.code);
                formData.append('redirect_uri', config.redirectUri);
                formData.append('duration', 'permanent');
                return doRequest(formData);

            }

        }

    }

    // if all else fails redirect to the Reddit login
    window.location = getLoginUrl();
    return Promise.reject('Redirecting to Reddit login...');

};

export const authorizedGet = (url, params) => {

    return new Promise((resolve, reject) => {

        getToken()
            .then(token => {
                axios(url, {
                    method: 'get',
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                    params
                })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
        ;

    });

};

export const authorizedPost = (url, data) => {

    const formData = new FormData();
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            formData.append(i, data[i]);
        }
    }

    return new Promise((resolve, reject) => {

        getToken()
            .then(token => {
                axios(url, {
                    method: 'post',
                    data: formData,
                    headers: {
                        Authorization: `bearer ${token}`,
                    }
                })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
        ;

    });

};
