<template>
    <div class="self-text" v-html="html"></div>
</template>

<script>
    export default {
        name: 'previewText',
        props: {
            html: String
        }
    };
</script>

<style scoped lang="scss">
    .self-text {
        border: 1px solid #eee;
        border-radius: 6px;
        padding: .5rem;

        &:before {
            position: absolute;
            top: -.4rem;
            left: 60px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 .4rem .4rem .4rem;
            border-color: transparent transparent #eee transparent;
        }

    }
</style>