<template>
    <div class="post">

        <div class="post-container">
            <div class="post-image-container">
                <template v-if="loading">
                    <div class="image-placeholder"></div>
                </template>
                <template v-else>
                    <a href="#" @click.prevent="togglePreview">
                        <div class="post-image" :style="{backgroundImage: `url(${thumbnailUrl})`}"></div>
                    </a>
                </template>
            </div>

            <template v-if="loading">
                <div class="post-content">
                    <div class="title-placeholder"></div>
                    <div class="meta-placeholder"></div>
                    <div class="links-placeholder"></div>
                </div>
            </template>
            <template v-else>
                <div class="post-content">
                    <h6 class="title"><a :href="linkUrl">{{ decodeHtml(title) }}</a></h6>
                    <div class="meta">
                        <div v-if="meta.subreddit">
                            <i class="fab fa-reddit"></i>
                            <router-link :to="{ name: 'subreddit', params: { subreddit: meta.subreddit } }">
                                {{ meta.subreddit }}
                            </router-link>
                        </div>
                        <div>
                            <i class="fas fa-clock"></i>
                            <span class="value">{{ posted }}</span>
                        </div>
                        <v-user :username="meta.author"></v-user>
                    </div>
                    <div class="links">
                        <div v-if="meta.subreddit">
                            <router-link :to="{ name: 'comments', params: { subreddit: meta.subreddit, id: meta.id }, meta: { title: title} }">
                                <i class="fas fa-comment"></i>
                                <span class="value">{{ commentCount }}</span>
                            </router-link>
                        </div>
                        <div>
                            <i class="far fa-dot-circle"></i>
                            <span class="value">{{ prettyScore }}</span>
                        </div>
                        <div>
                            <a :href="redditLink" target="_blank"><i class="fab fa-reddit"></i></a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <v-preview v-if="previewOpen" :post="meta" />
    </div>
</template>

<script>

    import distanceToNow from 'date-fns/formatDistanceToNow';
    import shortNumber from 'short-number';
    import decodeHtml from '../lib/decode-html';

    import vPreview from './preview';
    import vUser from './user';

    export default {
        name: 'post',
        props: {
            title: String,
            url: String,
            comments: Number,
            commentsUrl: String,
            score: Number,
            thumbnail: String,
            meta: Object,
            index: Number,
            loading: { type: Boolean, default: true },
        },
        components: { vPreview, vUser },
        data () {
            return {
                previewOpen: false,
            };
        },
        mounted () {
            this.previewOpen = this.meta.isOpen || false;
        },
        deactivated () {
            this.$destroy();
        },
        computed: {
            thumbnailUrl () {
                if (!this.thumbnail || this.thumbnail === 'self') {
                    return 'https://via.placeholder.com/140x78';
                } else {
                    return this.thumbnail;
                }
            },
            posted () {
                return distanceToNow(this.meta.created_utc * 1000);
            },
            commentCount () {
                return shortNumber(this.comments);
            },
            prettyScore () {
                return shortNumber(this.score);
            },
            redditLink () {
                return `https://www.reddit.com${this.meta.permalink}`;
            },
            linkUrl () {

                if (!this.url) return null;

                return this.url.replace(/(?:http|https):\/\/www.?reddit.com(\/r\/.+\/comments\/.*?\/).*/, '$1');

            }
        },
        methods: {
            togglePreview () {
                this.previewOpen = !this.previewOpen;
                this.$emit('open', { value: this.previewOpen, index: this.index });
            },
            decodeHtml (html) {
                return decodeHtml(html);
            }
        }
    };
</script>

<style scoped lang="scss">
  .post {
    position: relative;
    margin-bottom: 1rem;

    .post-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .post-image-container {
        margin-right: .5rem;
      }

      .post-image {

        width: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #eee;
        max-width: 100%;
        border: 1px solid #eee;

        @media screen and (min-width: 420px) {
          width: 140px;
        }

        &:before {
          content: '';
          padding-top: 60%;
          display: block;
        }
      }

      .title {
        margin-bottom: .15rem;
        font-size: .8rem;

        @media screen and (min-width: 420px) {
          font-size: 1rem;
        }
      }

      .meta {
        color: #999;
        margin-bottom: .25rem;
        font-size: .7rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & > div {
          flex: 0 1 auto;
          margin-right: .5rem;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .links {

        display: flex;

        & > div {
          flex: 0 1 auto;
          margin-right: .5rem;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .comments {
        display: inline-block;
        margin-left: .25rem;
      }

      a {
        color: inherit;
      }

    }

    .post-content {
      width: 100%;
    }

    .title-placeholder,
    .meta-placeholder,
    .links-placeholder {
      background: #eee;
      height: 1rem;
      margin-bottom: .5rem;
      width: 85%;
    }

    .meta-placeholder {
      width: 22%;
    }

    .links-placeholder {
      width: 15%;
    }

    .image-placeholder {
      background: #eee;
      width: 70px;
      height: 42px;

      @media screen and (min-width: 420px) {
        width: 140px;
        height: 84px;
      }
    }

  }
</style>
