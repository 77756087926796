<template>
    <img :src="src" alt="" class="preview-image">
</template>

<script>

    import decodeHtml from '../lib/decode-html'
    import throttle from '../lib/throttle';

    export default {
        name: 'previewImage',
        props: {
            images: Array,
        },
        data () {
            return {
                src: null,
            };
        },
        created () {
            this.src = this.findBestImage(this.images);
        },
        beforeMount () {

            throttle('resize', 'optimisedResize');

            window.addEventListener('optimisedResize', () => {
                this.src = this.findBestImage(this.images);
            });

        },
        methods: {
            findBestImage (images) {

                const screenWidth = document.body.clientWidth * ( window.devicePixelRatio || 1 );
                const imageOptions = this.getImagesArray(images[0]);

                // console.log(imageOptions);

                const bestImage = imageOptions.reduce((prev, curr) => {
                    return Math.abs(curr.width - screenWidth) < Math.abs(prev.width - screenWidth) ? curr : prev;
                });

                return decodeHtml(bestImage.url);

            },

            getImagesArray (images) {

                const result = [];
                if (images.source) {
                    result.push(images.source);
                }

                if (images.resolutions) {
                    images.resolutions.forEach(image => {
                        result.push(image);
                    });
                }

                return result.sort((a, b) => {
                    if (a.width === b.width) return 0;
                    return a.width < b.width ? -1 : 1;
                });

            }
        }
    };
</script>

<style scoped>
    .preview-image {
        max-width: 100%;
        max-height: 90vh;
    }
</style>
