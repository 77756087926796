<template>
    <div class="preview-container" v-html="html"></div>
</template>

<script>
    export default {
        name: 'previewEmbed',
        props: {
            html: String,
        }
    };
</script>

<style scoped>
    .preview-container {
        max-width: 100%;
        max-height: 90vh;
    }
</style>
