<template>
    <div class="container page">

        <v-sort :subreddit="subreddit"></v-sort>

        <v-sub-header v-if="subreddit && info" :about="info"></v-sub-header>

        <v-post v-for="(post,index) in posts"
                :index="index"
                :title="post.title"
                :outline="post.outline"
                :comments="post.num_comments"
                :score="post.score"
                :thumbnail="post.thumbnail"
                :url="post.url"
                :comments-url="post.id"
                :meta="post"
                :loading="loading"
                @open="handlePostOpen"
        >
        </v-post>

        <div class="loading-page" v-if="loadingPage">
            <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>

    </div>
</template>

<script>

    import queryString from 'querystring';

    import { authorizedGet } from '../lib/request';
    import { store, fetch, update } from '../lib/cache';
    import { EventBus } from '../lib/event-bus';
    import pluck from '../lib/pluck';
    import { debouncedScrollTo, purgeScrollPosition } from '../lib/scrollPosition';

    import vSort from '../components/sort';
    import vSubHeader from '../components/sub-header';
    import vPost from '../components/post';

    const PAGE_SIZE = 25;
    const CACHE_TTL = false;

    const postKeys = [
        'name',
        'title',
        'outline',
        'num_comments',
        'score',
        'thumbnail',
        'url',
        'id',
        'subreddit',
        'created_utc',
        'permalink',
        'is_self',
        'selftext_html',
        'media',
        'preview',
        'author'
    ];

    export default {
        name: 'subreddit',
        components: { vSort, vPost, vSubHeader },
        data () {
            return {
                loading: false,
                loadingPage: false,
                useCached: true,
                subreddit: this.$route.params.subreddit,
                sort: this.$route.params.sort || 'hot',
                sortType: this.$route.params.sortType || '',
                posts: [],
                info: false,
            };
        },
        beforeMount () {
            this.fetchPosts();
            this.fetchInfo();
            window.addEventListener('optimisedScroll', (e) => {
                const viewHeight = this.getDocumentHeight() - window.innerHeight;
                const scrollPosition = e.target.scrollY;
                if (scrollPosition >= viewHeight) {
                    this.getNextPage();
                }
            });

            EventBus.$on('refresh', () => {
                if (!this.subreddit) {
                    purgeScrollPosition();
                    window.scrollTo(0, 0);
                }
                this.fetchPosts(false);
            });

        },
        watch: {
            loading (value) {
                this.$emit('loading', value);
            },
            subreddit (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.posts = [];
                    this.$nextTick(() => {
                        this.fetchPosts();
                        this.fetchInfo();
                    });
                }
            },
            sort (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.posts = [];
                    this.$nextTick(() => {
                        this.fetchPosts();
                    });
                }
            },
            sortType (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.posts = [];
                    this.$nextTick(() => {
                        this.fetchPosts();
                    });
                }
            },
            $route (to) {
                this.subreddit = to.params.subreddit || false;
                this.sort = to.params.sort || 'hot';
                this.sortType = to.params.sortType;
            }
        },
        methods: {
            getDocumentHeight () {
                const body = document.body;
                const html = document.documentElement;
                return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

            },
            getCacheKey () {
                const subreddit = this.subreddit ? this.subreddit : 'home';
                const key = `posts.${subreddit}.${this.sort}.${this.sortType ? this.sortType : ''}`;
                return key.replace(/\.$/, '');
            },
            getPostsUrl (params = false) {
                let url = `https://oauth.reddit.com/${this.sort}`;
                if (this.subreddit) url = `https://oauth.reddit.com/r/${this.subreddit}/${this.sort}`;
                if (params) url += `?${queryString.stringify(params)}`;
                return url;

            },
            async fetchInfo (useCached = true) {
                if (this.subreddit) {
                    this.info = null;
                    const cacheKey = `about.${this.subreddit}`;
                    const cachedInfo = useCached ? await fetch(cacheKey) : false;

                    if (cachedInfo) {
                        this.info = cachedInfo;
                    } else {
                        authorizedGet(`https://oauth.reddit.com/r/${this.subreddit}/about`)
                          .then(res => {
                              this.info = res.data.data;
                          })
                          .catch(err => {
                              EventBus.$emit('error', err);
                              console.warn(err);
                          });
                    }
                }
            },
            async fetchPosts (useCached = true) {
                if (!this.loading) {
                    const cacheKey = this.getCacheKey();
                    this.loading = true;
                    const cachedPosts = useCached ? await fetch(cacheKey) : false;
                    if (cachedPosts) {
                        this.posts = cachedPosts;
                        this.loading = false;
                    } else {
                        const params = { limit: PAGE_SIZE };
                        if (this.sort === 'top' && this.sortType) {
                            params.t = this.sortType;
                        }
                        const url = this.getPostsUrl(params);
                        authorizedGet(url)
                          .then(async (res) => {
                              this.posts = res.data.data.children.map(post => pluck(post.data, postKeys));
                              await store(cacheKey, this.posts, CACHE_TTL);
                          })
                          .catch(err => {
                              EventBus.$emit('error', err);
                              console.warn(err);
                          })
                          .finally(() => {
                              this.useCached = true;
                              this.loading = false;
                          });
                    }
                }
            },
            getNextPage () {
                if (!this.loading && !this.loadingPage) {
                    this.loadingPage = true;
                    const params = {
                        after: this.posts[this.posts.length - 1].name,
                        count: this.posts.length,
                        limit: PAGE_SIZE,
                    };
                    if (this.sort === 'top' && this.sortType) {
                        params.t = this.sortType;
                    }
                    const cacheKey = this.getCacheKey();
                    const url = this.getPostsUrl(params);

                    authorizedGet(url)
                      .then(res => {
                          this.posts = this.addPosts(res.data.data.children.map(post => post.data));
                          update(cacheKey, this.posts, CACHE_TTL);
                      })
                      .catch(err => {
                          EventBus.$emit('error', err);
                          console.warn(err);
                      })
                      .finally(() => {
                          this.loadingPage = false;
                      });

                }

            },
            handlePostOpen (payload) {
                const { value, index } = payload;
                const post = this.posts[index];
                const cacheKey = this.getCacheKey();
                post.isOpen = value;
                this.posts.splice(index, 1, post);
                update(cacheKey, this.posts, CACHE_TTL);
            },
            findPostById (id) {
                return this.posts.findIndex((post) => {
                    return post.id === id;
                });
            },
            addPosts (posts) {
                // create a clone of the posts
                let newPosts = JSON.parse(JSON.stringify(this.posts));
                posts.forEach(post => {
                    const index = this.findPostById(post.id);
                    if (index === -1) {
                        newPosts.push(post);
                    }
                });
                return newPosts;
            }
        }
    };
</script>

<style scoped lang="scss">
    .loading-page {
        position: relative;
        height: 100px;

        .fas {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: (-50%, -50%);
        }
    }
</style>
