import VueRouter from 'vue-router';
import Vue from 'vue';
import App from './App.vue';

Vue.use(VueRouter);

new Vue({
    el: '#app',
    render: h => h(App)
});

// Activate the service worker
// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', function() {
//         let swPath = `service-worker.js`;
//         navigator.serviceWorker.register(swPath).then(function(registration) {
//             // Registration was successful
//             console.log('ServiceWorker registration successful with scope: ', registration.scope);
//         }, function(err) {
//             // registration failed :(
//             console.log('ServiceWorker registration failed: ', err);
//         });
//     });
// }