<template>
    <header class="sub-header">
        <template v-if="loading">
            <div class="loading-header"></div>
        </template>
        <template v-else>
            <h2>{{ about.title }}</h2>
            <div class="subscribers">
                {{ about.active_user_count.toLocaleString() }} online / {{ about.subscribers.toLocaleString() }} subscribers
                <a href="#" @click.prevent="toggleSubscription">
                    <template v-if="changingStatus">
                        <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <template v-else>
                        <i class="fas fa-minus-square" v-if="about.user_is_subscriber"></i>
                        <i class="fas fa-plus-square" v-else></i>
                    </template>
                </a>
            </div>
            <div class="description-container" v-html="descriptionHtml"></div>
        </template>
    </header>
</template>

<script>

    import decodeHtml from '../lib/decode-html';
    import { authorizedPost } from '../lib/request';

    export default {
        name: 'sub-header',
        data () {
            return {
                loading: true,
                changingStatus: false,
            };
        },
        props: {
            about: Object | Boolean
        },
        watch: {
            about (value) {
                this.loading = !value;
            }
        },
        mounted () {
            this.loading = !this.about;
        },
        computed: {
            descriptionHtml () {
                return decodeHtml(this.about.public_description_html);
            }
        },
        methods: {
            toggleSubscription () {

                if (!this.changingStatus) {

                    this.changingStatus = true;

                    const action = this.about.user_is_subscriber ? 'unsub' : 'sub';

                    authorizedPost('https://oauth.reddit.com/api/subscribe', {
                        action,
                        sr: this.about.name,
                    })
                      .then(res => {
                          if (res.status === 200) {
                              this.about.user_is_subscriber = !this.about.user_is_subscriber;
                          }
                          this.changingStatus = false;
                      })
                      .catch(err => {
                          console.warn(err);
                          this.changingStatus = false;
                      })
                    ;

                }

            }
        }
    };
</script>

<style scoped lang="scss">
    .sub-header {
        margin-bottom: 1rem;
        border-bottom: 1px solid #eee;
        padding-bottom: 1rem;

        h2 {
            margin-bottom: .5rem;
            font-size: 16px;
        }

        .subscribers {
            font-size: .9rem;
        }
    }

    .loading-header {
        background: #eee;
        height: 100px;
        width: 100%;
        position: relative;
    }

    .description-container {
        margin-top: .5rem;
    }
</style>
<style lang="scss">
    .description-container {
        .md {
            p {
                font-size: .7rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>